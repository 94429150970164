import React from "react"
import Work from "./Work"
// import MenuExampleVertical from "./VerticalMenu"
// import Header from "./Header"
// import Illustrations from "./Illustrations"


class Home extends React.Component{
  render() {
    return(
      <>
        <Work />
        </>
    )
  }
}

export default Home
